<template>
  <div class="money">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>申请提现</div>
    </div>
    <van-cell-group>
      <van-field
        v-model="value"
        type="digit"
        label="提现金额（元）:"
        label-width="120"
        :placeholder="'最低提现金额'+min+'金币'"
        :border="false"
      />
    </van-cell-group>
    <div class="btn footer" @click="handleMoney">申请提现</div>
    <!-- 弹框 -->
    <van-overlay :show="show">
      <div class="exchange_popup">
        <div class="popup_img">
          <img src="../../assets/image/close.svg" alt />
        </div>
        <div class="popup_content" @click.stop>
          <div class="popup_title">申请成功</div>
          <div class="popup_prompt">审核过后预计3-5个工作日到账，请耐心等待</div>
          <div class="btn btn_w" @click="handleConfirm">确定</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "",
      show: false,
      min:""
    };
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    async handleMoney() {
      if (this.value == "") {
        this.$notify({ type: "danger", message: "提现金额不能为空！" });
      }
      const data = await this.$api.txApply({
        money: this.value
      });
      if (data.data.code === 1) {
        this.show = true;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
      console.log(data, "data");
    },
    handleConfirm() {
      this.$router.push({
        path: "/commission/index"
      });
    },
   async handleTxMin(){
     const data = await this.$api.txMin()
      if (data.data.code === 1) {
        this.min = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
     console.log(data,"最低时间")
    }
  },
  mounted() {
    this.handleTxMin();
  }
};
</script>
<style lang="scss" scoped>
.money {
  width: 375px;
  padding-top: 40px;
  box-sizing: border-box;
  .footer {
    margin-top: 292px;
  }
  .exchange_popup {
    position: relative;
    .popup_img {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 104px;
      right: 32px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .popup_content {
      width: 310px;
      height: 210px;
      background: rgba(255, 255, 255, 1);
      border-radius: 6px;
      position: absolute;
      top: 136px;
      right: 32px;
      padding-top: 20px;
      box-sizing: border-box;
      .popup_title {
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }
      .popup_prompt {
        width: 200px;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        line-height: 22px;
        position: absolute;
        top: 62px;
        left: 50px;
      }
      .btn_w {
        position: absolute;
        width: 210px;
        bottom: 26px;
        left: 50px;
      }
    }
  }
}
</style>